import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-new-passport',
  templateUrl: './new-passport.component.html',
  styleUrls: ['./new-passport.component.css']
})
export class NewPasswortComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  id_new_password: any;
  // tslint:disable-next-line:max-line-length variable-name
  client_email: any;
  // tslint:disable-next-line:max-line-length variable-name
  login: any;
  // tslint:disable-next-line:max-line-length variable-name
  new_password: any;
  constructor(public meta: Meta, public pageTitle: Title, private activatedRoute: ActivatedRoute, private router: Router, private userService: UserService) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.client_email = params.email;
      this.login = params.login;
    });
  }

  ngOnInit() {
    this.id_new_password = this.activatedRoute.snapshot.url[2].path;
    this.get_user(this.id_new_password);
  }
  getCurrentDate() {
    // tslint:disable-next-line:prefer-const
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return  dd + '-' + mm + '-' + yyyy;
  }

  get_user(id) {
    this.userService.getUserDetailsForPassword(id)
      .subscribe(res => {
        this.id_new_password = res.data.id;
        this.client_email = res.data.email;
        this.login = res.data.login;
        console.log(res.data);
        if (res.data.length === 0) {
          this.router.navigate(['/']);
        }
        // tslint:disable-next-line:max-line-length
        this.router.navigate(['/password/forget'], { queryParams: { email: this.client_email, login: this.login ,  date: this.getCurrentDate(), application: 'certi_kap_v1' }});
      }, (err) => {
        this.router.navigate(['/']);
      });
  }

  change_password() {
    console.log(this.new_password);
  }
}
