import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {FormCreatorService} from '../../../../services/form-creator.service';

@Component({
  selector: 'app-auto-inscription-start',
  templateUrl: './auto-inscription-start.component.html',
  styleUrls: ['./auto-inscription-start.component.css']
})
export class AutoInscriptionStartComponent implements OnInit {
  // tslint:disable-next-line:max-line-length variable-name
  public user_functions = [
    {num: 1, job: 'Direction/Cadre'},
    {num: 2, job: 'Ingénieur'},
    {num: 3, job: 'Employé'},
    {num: 4, job: 'Contremaître, chef d\'équipe'},
    {num: 5, job: 'Technicien qualifié'},
    {num: 6, job: 'Ouvrier qualifié'},
    {num: 7, job: 'Ouvrier non-qualifié'},
    {num: 8, job: 'Manoeuvre/Aidant'},
    {num: 9, job: 'Autre'},
  ];
  // tslint:disable-next-line:variable-name
  public user_brevets = [
    {num: 1, brevet: 'BA4-BA5'},
    {num: 2, brevet: 'Chariot Élévateur'},
    {num: 3, brevet: 'Nacelle'},
    {num: 4, brevet: 'Ponts roulants'},
    {num: 5, brevet: 'Port du harnais'},
    {num: 6, brevet: 'Autre'}
  ];
  // tslint:disable-next-line:max-line-length variable-name
  public url_details: string;
  // tslint:disable-next-line:max-line-length variable-name
  public group_id: any;
  // tslint:disable-next-line:max-line-length
  constructor(public meta: Meta, public pageTitle: Title, private activatedRoute: ActivatedRoute, private router: Router, private userService: UserService, private formCreatorService: FormCreatorService) { }


  ngOnInit() {
    this.url_details = this.activatedRoute.snapshot.url[3].path;
    // console.log(this.url_details);
    this.get_form(this.url_details);
  }
  getCurrentDate() {
    // tslint:disable-next-line:prefer-const
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    return  dd + '-' + mm + '-' + yyyy;
  }

  get_form(token) {
    this.formCreatorService.getOneForm(token)
      .subscribe(res => {
        this.group_id = res.data.group_id;
        if (res.success === false) {
          this.router.navigate(['/']);
          return false;
        } else {

         // console.log(this.group_id);
          // tslint:disable-next-line:max-line-length
          this.router.navigate(['/v1/form/auto-inscription'], { queryParams: { token, group: this.group_id, date: this.getCurrentDate(), application: 'certi_kap_v1' }});
        }
        // tslint:disable-next-line:max-line-length
      }, (err) => {
        console.log(err);
        // this.router.navigate(['/']);
      });
  }

}
