import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {CertificationService} from '../../../../../services/certification.service';
import {UserService} from '../../../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SiteService} from '../../../../../services/site.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SubCertificationService} from '../../../../../services/sub-certification.service';
import {ConfigService} from '../../../../../services/config.service';
import {FormArray, FormBuilder, FormControl} from "@angular/forms";

@Component({
  selector: 'app-edit-sous-certification',
  templateUrl: './edit-sous-certification.component.html',
  styleUrls: ['./edit-sous-certification.component.css']
})
export class EditSousCertificationComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  public single_sub_certificats = {
    name: null,
    description: null,
    date: null,
    type: null,
    delay: null,
    certificat_id: null,
    id: null
  };
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  show = 10;
  vQuery: string;
  addingSuccess: boolean;
  // tslint:disable-next-line:max-line-length
  readioSelected: number;
  // tslint:disable-next-line:max-line-length
  public certificats;
  private myForm: any;
  public siteFormArray: any;
  public array = [];
  private array_sites_id: any;
  private val: any;
  private site_id_sub_certification: any;
  public sites= [];
  // tslint:disable-next-line:max-line-length
  constructor(private configService: ConfigService,private siteService: SiteService,private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private certificationService: CertificationService, private userService: UserService, private spinner: NgxSpinnerService, private el: ElementRef, private renderer: Renderer2, private subCertificationService: SubCertificationService) { }


  ngOnInit() {
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.get_sous_certificat(this.activatedRoute.snapshot.url[4].path);
    this.get_list_certifications();
    this.get_list_sites();
    this.myForm = this.formBuilder.group({
      site_id: this.formBuilder.array([])
    });
    this.siteFormArray = <FormArray>this.myForm.controls.site_id;
  }
  // tslint:disable-next-line:variable-name
  get_sous_certificat(id_sub_certificat: any) {
    this.spinner.show();
    this.subCertificationService.getOneSousCertificat(id_sub_certificat)
      .subscribe(res => {
        // @ts-ignore
        this.single_sub_certificats = res.data;
        this.site_id_sub_certification = res.site_id;
        this.site_id_sub_certification.forEach(element => this.check(element,true));
        if (this.single_sub_certificats.date) {
          this.readioSelected = 1;
        }
        if (this.single_sub_certificats.delay) {
          this.readioSelected = 2;
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }
  edit_sous_certificat() {
    const body = {
      name: this.single_sub_certificats.name,
      description: this.single_sub_certificats.description,
      date: this.single_sub_certificats.date,
      delay: this.single_sub_certificats.delay,
      certificat_id: this.single_sub_certificats.certificat_id,
      id: this.single_sub_certificats.id,
      array_site_id : this.siteFormArray.value,
    };
    this.subCertificationService.updateSousCertificat(body)
      .subscribe(res => {
        this.addingSuccess = true;
        setTimeout (() => {
          this.addingSuccess = false;
        }, 2000);
      }, (err) => {
        console.log(err);
      });
  }
  changeValidite(e) {
    console.log(e.target.value);
    this.readioSelected = e.target.value;
  }
  get_list_certifications() {
    this.spinner.show();
    this.certificationService.getCertificat()
      .subscribe(res => {
        // @ts-ignore
        this.count_certificat = res.data.length;
        // @ts-ignore
        this.certificats = res.data.certificat;
        console.log(this.certificats);
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.sites = res.data;
        this.array_sites_id = res.id;
        console.log(res.data);
      }, (err) => {
        console.log(err);
      });
  }

  check(site_id: number, event: boolean){
    if(event) {
      this.siteFormArray.push(new FormControl(site_id));
    } else {
      let index = this.siteFormArray.controls.findIndex(x => x.value == site_id)
      this.siteFormArray.removeAt(index);
    }

  }

  check_value(site_id){
    this.val = this.siteFormArray.value.includes(site_id);
    if(this.val){
      return true;
    }
    else{
      return false;
    }
  }
}
