import { Component, OnInit } from '@angular/core';
import {FormCreatorService} from '../../../../services/form-creator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../../../services/data.service';

@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.css']
})
export class EditFormComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private activatedRoute: ActivatedRoute, private formCreatorService: FormCreatorService, private router: Router, private dataService: DataService) {
    this.myData = '';
    this.dataService.getData().subscribe(data => {
      // do stuff with the data, for example:
      this.myData = data.htmlCode;
      console.log(this.myData);
    });
    this.dataService.getDataGroup().subscribe(data => {
      // do stuff with the data, for example:
      this.myDataGroup = data.htmlCodeGroup;
    });

  }
// tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));
  // tslint:disable-next-line:variable-name
  public connected_id = this.user_conntected.id;
  // tslint:disable-next-line:variable-name
  public id_form: any;
  // tslint:disable-next-line:variable-name
  public old_forms: any;
  // tslint:disable-next-line:variable-name
  public form_creator = {
    name: null,
    firstname: null,
    phone: null,
    password: null,
    confirm_password: null,
    site_id: null,
    email: null,
    login: null,
    profile_id: null,
    custom_profile_id: null,
    publish: 0
  };
  public myData: any;
  public myDataGroup: any;
  groups: any;
  // tslint:disable-next-line:variable-name
  groups_id: any;
  oldDataFormDontNewAdding: any;
  // tslint:disable-next-line:variable-name
  showGif: boolean;
  private sub: any;
  // tslint:disable-next-line:variable-name
  label_form_auto_inscription: any;
  ngOnInit() {
    console.log('Edit page');
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id_form = params.id;
      this.get_current_form(this.id_form);
    });
    this.get_site_group(this.site_id);

  }
  // tslint:disable-next-line:variable-name
  get_site_group(site_id) {
    this.showGif = true;
    this.formCreatorService.getListOfGroupBySite(site_id)
      .subscribe(res => {
        console.log(res);
        this.groups = res.data;
        this.showGif = false;
      }, (err) => {
        console.log(err);
      });
  }
  // tslint:disable-next-line:variable-name
  get_current_form(id_form) {
    this.formCreatorService.getForm(id_form)
      .subscribe(res => {
        if (res.data.content !== '') {
          this.old_forms = JSON.parse(res.data.content);
          this.myData = JSON.parse(res.data.content);
        }
        this.groups_id = res.data.group_id;
        this.label_form_auto_inscription = res.data.label;
      }, (err) => {
        console.log(err);
      });
  }
  getGroupChoose(event: any) {
    this.groups_id = event.target.value;
  }

  edit_form() {
    console.log(this.myData.length);
    console.log(typeof this.myData);
    console.log(this.myData);
    // return false;
    const body = {
      content_form: this.myData,
      site_id: this.site_id,
      user_id: this.connected_id,
      form_id: this.id_form,
      group_id: this.groups_id,
      label: this.label_form_auto_inscription,
    };
    console.log(body);
    this.formCreatorService.updateform(body)
      .subscribe(res => {
        this.router.navigate(['dashboard/administrator/formulaire']);
      }, (err) => {
        console.log(err);
      });
  }
}
