import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import {MatTreeModule} from '@angular/material/tree';
import {VerificationService} from '../../../services/verification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService} from '../../../services/config.service';
import { OrderPipe } from 'ngx-order-pipe';
import {ExportToCsv} from 'export-to-csv';
import {SiteService} from '../../../services/site.service';
import {User} from "../../../models/user.model";
import {Verification} from "../../../models/verification.model";
import {Site} from "../../../models/site.model";
import {VerificationSite} from "../../../models/verification-site.model";
import {Certificat} from "../../../models/certificat.model";
import {VerificationCertificat} from "../../../models/verification-certificat.model";

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {
  order = 'verifications.name';
  reverse = false;
  public verifications: any;
  // tslint:disable-next-line:variable-name
  public site_id: any;
  public sites: any;
  sortedCollection: any[];
  // tslint:disable-next-line:max-line-length variable-name
  public certifications_for_export: any;
  // tslint:disable-next-line:max-line-length
  private site_all: any;
    constructor(private siteService: SiteService, private orderPipe: OrderPipe, private userService: UserService, private spinner: NgxSpinnerService, private el: ElementRef, private renderer: Renderer2, private verificationService: VerificationService, private router: Router, private activatedRoute: ActivatedRoute, private configService: ConfigService) {
      this.sortedCollection = this.orderPipe.transform(this.verifications, 'verifications.name');
    }

  // tslint:disable-next-line:variable-name radix
    public profile_id = parseInt(localStorage.getItem('profile_id'));

    // tslint:disable-next-line:variable-name
    public count_verification: any;
  // tslint:disable-next-line:variable-name
    public list_certificat: any;
    show = 10;
    vQuery: string;
    addingSuccess: boolean;
  // tslint:disable-next-line:max-line-length variable-name
   count_certifification: any;

  // tslint:disable-next-line:variable-name
  public showLoader = true;
  // tslint:disable-next-line:variable-name
  public showLabel = true;
    // tslint:disable-next-line:use-lifecycle-interface

    //Initialisation Vérification

  AllVerifications: Verification[] = [];
  AllSites: Site[] = [];
  AllverificationsSites: VerificationSite[] = [];
  Allcertification: Certificat[] = [];
  VerificationCertificat: VerificationCertificat[] = [];


  ngOnInit() {
      this.configService.checkUSerAccessByProfile(this.profile_id);
      this.get_list_verification();
      this.get_list_sites();
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  get_list_verification() {
    this.spinner.show();
    this.verificationService.getVerification()
      .subscribe(res => {
        // @ts-ignore
        this.count_verification = res.data.verifications.length;
        // @ts-ignore
        this.verifications = res.data.verifications;
        this.certifications_for_export = res.data.certifications_for_export;
        this.count_certifification = res.data.certifications;
        this.AllVerifications = res.verifications_all;
        this.AllSites = res.sites_all;
        this.AllverificationsSites = res.VerificationSite;
        this.Allcertification = res.certifications_all;
        this.VerificationCertificat = res.verification_certificat;
        this.site_all = res.site_all;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
  }

  // tslint:disable-next-line:variable-name
  editer(id_verification) {
    this.router.navigate(['/dashboard/administrator/verification/editer/' + id_verification], { relativeTo: this.activatedRoute});
  }

  // tslint:disable-next-line:variable-name
  supprimer(id_verification) {
    Swal.fire({
      title: 'Etes-vous sûr(e) ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        const body = {
          id: id_verification
        };
        // @ts-ignore
        this.verificationService.deleteVerification(body)
          .subscribe(res => {
            this.addingSuccess = true;
            this.get_list_verification();
          }, (err) => {
            console.log(err);
          });
        Swal.fire(
          'Supprimé !',
          'Vérification supprimée avec succès !',
          'success'
        );
      }
    });
  }
  // tslint:disable-next-line:variable-name

  // tslint:disable-next-line:variable-name
  get_certification_list_verification(id_verification: any) {
    this.verificationService.getCertificationByVerification(id_verification)
      .subscribe(res => {
        // @ts-ignore
        this.list_certificat = res.data;
      }, (err) => {
        console.log(err);
      });
  }

  listingCertificat(id: any) {
    this.showLoader = true;
    this.showLabel = false;
    this.verificationService.getCertificationByVerification(id)
      .subscribe(res => {
        // @ts-ignore
        this.showLabel = true;
        this.list_certificat = res.data;
        this.showLoader = false;
      }, (err) => {
        console.log(err);
      });
  }

  exporter() {
    const dataToExport = [];
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Exportation des vérifications',
      filename: 'liste_verification_' + this.formatDate(),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      // tslint:disable-next-line:max-line-length
      headers: ['ID', 'Libellés', 'Certifications liées', 'Sites']
    };
    this.verifications.forEach((rdata, index) => {
     let labelCert = '';
     this.certifications_for_export.forEach((certData, Certindex) => {

       certData.forEach((cert, certID) => {
         const fake = '';
         if (cert.verification_id === rdata.id) {
             labelCert += cert.certifName + ' - ';
         }
       });

      });
     // console.log(labelCert.slice(0, -2));
     const  data = {
        nom: rdata.code_verif,
        label: rdata.name,
        certification: labelCert.slice(0, -2),
        sites: rdata.siteName,
        // sous_certifications: labelSubCert,
      };
     dataToExport.push(data);
    });
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(dataToExport);
  }
  formatDate() {
    // tslint:disable-next-line:one-variable-per-declaration
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    const hour = d.getHours();
    const minute = d.getMinutes();
    const seconde = d.getSeconds();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    const dateJoin = [year, month, day].join('');
    const hourJOin = [hour, minute, seconde].join('');
    return [dateJoin, hourJOin].join('_');
  }
  // tslint:disable-next-line:variable-name
  get_verification_sites(id_site) {
    this.spinner.show();
    this.verificationService.get_verification_sites_list(id_site)
      .subscribe(res => {
        this.count_verification = res.data.length;
        this.verifications = res.data;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.sites = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  filterSite(value) {
      this.get_verification_sites(value);
  }
}
