import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {Title} from '@angular/platform-browser';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from "sweetalert2";
import {User} from "../../models/user.model";
import {GroupeService} from "../../services/groupe.service";
import {Groupe} from "../../models/groupe.model";
import {ChoiceScreenService} from "../../services/choice-screen.service";
import {ChoiceScreen} from "../../models/choice-screen.model";

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.css']
})
export class AdministratorComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private router: Router,
              private configService: ConfigService,
              private userService: UserService,
              private config: ConfigService,
              public pageTitle: Title,
              private spinner: NgxSpinnerService,
              private groupeService: GroupeService,
              private choiceScreenService: ChoiceScreenService) {
    this.config.redirectTools();
  }
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));
  // tslint:disable-next-line:variable-name
  public connected_id = this.user_conntected.id;
  // tslint:disable-next-line:variable-name
  public fake_userid = 9999;
  // tslint:disable-next-line:variable-name
  public c_superviseur;
  public user_id;
  // tslint:disable-next-line:variable-name
  public c_client;
  // tslint:disable-next-line:variable-name
  public c_verificateur;
  // tslint:disable-next-line:variable-name
  public c_intervenant;
  path: any;
  // tslint:disable-next-line:variable-name
  showClient = true;
  showSuperviseur = true;
  showVerificateur = true;
  showIntervenant = true;

  //Models
  userFind: User[];
  change_password: any;

  //GROUPES USER
  userGroups: Groupe[] =[];
  userGroupsId = [];

  //CHOICE SCREEN
  ChoiceScreen = new ChoiceScreen();

  ngOnInit() {

    if(this.user_conntected.id)
    {
      this.user_id = this.user_conntected.id;
    }
    else{
      this.user_id = this.user_conntected.user_id;
    }

    this.userGroupsCheck();

    this.path = this.router.url;
    this.configService.checkUSerAccessByProfile(this.profile_id);
    if(this.user_conntected.profile_id == 11){
      this.get_list_client();
      this.get_list_superviseur(this.profile_id, this.site_id);
      this.get_list_verificateur(this.profile_id, this.site_id, this.connected_id);
      this.get_list_intervenant(this.profile_id, this.site_id, this.user_id);
    }
    else if(this.user_conntected.profile_id == 1 || this.user_conntected.profile_id == 12){
      this.get_list_superviseur(this.profile_id, this.site_id);
      this.get_list_verificateur(this.profile_id, this.site_id, this.connected_id);
      this.get_list_intervenant(this.profile_id, this.site_id, this.user_id);
    }
    //profile === 4 || profile === 2 || profile === 5 || profile === 6 || profile === 9 || profile === 10
    else if(this.user_conntected.profile_id == 4 || this.user_conntected.profile_id == 2 ||
      this.user_conntected.profile_id == 5 || this.user_conntected.profile_id == 6 ||
      this.user_conntected.profile_id == 9 || this.user_conntected.profile_id == 10){
      this.get_list_verificateur(this.profile_id, this.site_id, this.connected_id);
      this.get_list_intervenant(this.profile_id, this.site_id, this.user_id);
    }
    else if(this.user_conntected.profile_id == 8 || this.user_conntected.profile_id == 13){
      this.get_list_intervenant(this.profile_id, this.site_id, this.user_id);
    }



  }
  // Client == Suprvisuers
  get_list_client() {
    this.userService.getCountClient()
      .subscribe(res => {
        // @ts-ignore
        this.c_client = res.count;
        this.showClient = false;
      }, (err) => {
        console.log(err);
      });
  }
  // Superviseur == Sup bonze, sup or, etc
  // tslint:disable-next-line:variable-name
  get_list_superviseur(profile_id, site_id) {
    this.userService.getCountSuperviseur(profile_id, site_id)
      .subscribe(res => {
        // @ts-ignore
        this.c_superviseur = res.count;
        this.showSuperviseur = false;
      }, (err) => {
        console.log(err);
      });
  }
  // tslint:disable-next-line:variable-name
  get_list_verificateur(profile_id, site_id, user_id) {
    this.userService.getCountVerificateur(profile_id, site_id, user_id)
      .subscribe(res => {
        // @ts-ignore
        this.c_verificateur = res.count;
        this.showVerificateur = false;
      }, (err) => {
        console.log(err);
      });
  }
  // tslint:disable-next-line:variable-name
  get_list_intervenant(profile_id, site_id, user_id) {
    this.userService.getCountIntervenant(profile_id, site_id, user_id)
      .subscribe(res => {
        // @ts-ignore
        this.c_intervenant = res.count;
        this.showIntervenant = false;
      }, (err) => {
        console.log(err);
      });
  }

  sso_active() {
    this.spinner.show();
    const body = {
      // ssoKey : 'h-kaptitude'
      Id : this.user_conntected.user_id,
      ssoActive : this.user_conntected.active,
      user_login : this.user_conntected.login
    };
    this.userService.sso_connexion_active(body)
      .subscribe(res => {
        // @ts-ignore
        if (res.sso_data === '') {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Votre compte est inconnu sur Talensoft, veuillez contacter l\'administrateur pour plus détails.',
            footer: '<a href>Equipe Kaptitude</a>'
          });
          return false;
        }
        this.spinner.hide();
        // @ts-ignore
        window.location.replace(this.configService.ELMS_url + 'sso.php?skey=' + res.sso_data, "_blank");
      }, (err) => {
        console.log(err);
      });
  }


  viewCertificats(id) {
    if(!id){
      id = this.user_conntected.user_id;
    }
    this.router.navigate(['dashboard/administrator/apprenant/certificats/' + id]);
  }

  userGroupsCheck(){
    const body = {
      user_login : this.user_conntected.login
    }
    this.groupeService.getGroupsByUser(body)
      .subscribe(res => {
        if (res.success == true) {
          this.userGroups = res.groupes
          this.userGroups.forEach(groupe => {
            this.userGroupsId.push(groupe.id);
          });

        }
      }, (err) => {
        console.log(err);
      });
  }

  checkIfChoiceScreen(){
    const body = {
      groups : this.userGroupsId,
    };
    this.choiceScreenService.getByGroups(body)
      .subscribe(res => {
        if (res.success == true) {
          this.ChoiceScreen = res.choiceScreen
          const valourTrouve = this.ChoiceScreen.groupes.some(groupe => this.userGroupsId.includes(groupe.id));
          if (valourTrouve) {
            //sso
            this.sso_active();
          } else {
            // Aucune des valeurs recherchées n'a été trouvée dans le tableau donc pop up
            this.openCustomPopup();
          }
        }
        else{
          this.sso_active();
        }
      }, (err) => {
        console.log(err);
      });
  }
  test(){
    this.checkIfChoiceScreen();
  }

  showCustomPopup = false;
  selectedChoice: number | null = null;

  openCustomPopup() {
    this.showCustomPopup = true;
  }

  closeCustomPopup() {
    this.showCustomPopup = false;
  }

  inscriptionGroupChoice(choice: number) {
    this.selectedChoice = choice;
    this.closeCustomPopup();
    const body = {
      user_login : this.user_conntected.login,
      g_remote_id : this.selectedChoice
    }
    this.groupeService.registerUserGroup(body)
      .subscribe(res => {
        if (res.success == true) {
          this.sso_active();
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Un problème est survenue',
            footer: '<a href>Equipe Kaptitude</a>'
          });
        }
      }, (err) => {
        console.log(err);
      });
  }


}
