import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fildariane',
  templateUrl: './fildariane.component.html',
  styleUrls: ['./fildariane.component.css']
})
export class FildarianeComponent implements OnInit {
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  constructor() { }

  ngOnInit() {
  }

}
