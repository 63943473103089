import { Component, OnInit } from '@angular/core';
import { SiteService } from 'src/app/services/site.service';
import { UserService } from 'src/app/services/user.service';
import { ProfileService } from 'src/app/services/profile.service';
import Swal from 'sweetalert2';
import {CertificationService} from '../../../../services/certification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfigService} from "../../../../services/config.service";
import {FormArray, FormBuilder, FormControl} from "@angular/forms";

@Component({
  selector: 'app-add-certification',
  templateUrl: './add-certification.component.html',
  styleUrls: ['./add-certification.component.css']
})
export class AddCertificationComponent implements OnInit {

  public certification = {
    name: null,
    type_validite: null,
    description: null,
    date: null,
    type: null,
    delay: null,
    id: null
  };
  public sites: any;
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  public addingSuccess: boolean;
  public profiles: any;
  public certificats: any;
  // tslint:disable-next-line:max-line-length
  public messageText;
  // tslint:disable-next-line:max-line-length
  choice: number;
  readioSelected: number;
  private array_sites_id: any;
  private myForm: any;
  public siteFormArray: any;
  private val: any;

  // tslint:disable-next-line:max-line-length
  constructor(private configService: ConfigService,private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private siteService: SiteService, private userService: UserService, private certificationService: CertificationService, private profileService: ProfileService) { }

  ngOnInit() {
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.get_list_certifications();
    this.get_list_sites();
    this.myForm = this.formBuilder.group({
      site_id: this.formBuilder.array([])
    });
  }
  get_list_certifications() {
    this.spinner.show();
    this.certificationService.getCertificat()
      .subscribe(res => {
        // @ts-ignore
        this.count_certificat = res.data.length;
        // @ts-ignore
        this.certificats = res.data.certificat;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }


  add_certificat() {
    // tslint:disable-next-line:max-line-length
    if (this.certification.name === null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez vos saisies !'
      });
      return false;
      // tslint:disable-next-line:align
    }
    if (this.certification.type === '0' && this.certification.id === null ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez sélectionner un certificat !'
      });
      return false;
    }
    const body = {
      name: this.certification.name,
      description: this.certification.description ? this.certification.description : this.certification.name,
      type_validite: this.readioSelected,
      date: this.certification.date,
      certificat_id: this.certification.id,
      type: this.certification.type,
      delay: this.certification.delay,
      array_site_id : this.siteFormArray.value,
    };
    console.log(body);
    this.certificationService.addCertificats(body)
      .subscribe(res => {
        this.addingSuccess = true;
        // @ts-ignore: Unreachable code error
        this.messageText = res.message;
        this.certification.name = '';
        this.certification.description = '';
        this.certification.type_validite = '';
        this.certification.date = '';
        this.certification.type = '';
        this.get_list_certifications();
        setTimeout (() => {
          this.addingSuccess = false;
        }, 5000);
      }, (err) => {
        this.spinner.hide();
      });
  }

  changeValidite(e) {
    console.log(e.target.value);
    this.readioSelected = e.target.value;
    this.certification.type_validite =  this.readioSelected;
  }

  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.sites = res.data;
        this.array_sites_id = res.id;
      }, (err) => {
        console.log(err)
      });
  }

  check(site_id: number, event: boolean){

    this.siteFormArray = <FormArray>this.myForm.controls.site_id;

    if(event) {
      this.siteFormArray.push(new FormControl(site_id));
    } else {
      let index = this.siteFormArray.controls.findIndex(x => x.value == site_id)
      this.siteFormArray.removeAt(index);
    }
  }

  check_value(site){
    this.val = this.siteFormArray.value.includes(site);
    if(this.val){
      return true;
    }
    else{
      return false;
    }
  }
}
