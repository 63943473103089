import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {SiteService} from '../../../../services/site.service';
import {UserService} from '../../../../services/user.service';
import {CertificationService} from '../../../../services/certification.service';
import {ProfileService} from '../../../../services/profile.service';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';
import {SubCertificationService} from '../../../../services/sub-certification.service';
import {ConfigService} from "../../../../services/config.service";
import {FormArray, FormBuilder, FormControl} from "@angular/forms";

@Component({
  selector: 'app-edit-certification',
  templateUrl: './edit-certification.component.html',
  styleUrls: ['./edit-certification.component.css']
})
export class EditCertificationComponent implements OnInit {
  // @ts-ignore
  @ViewChild('nameSubCertification', {static: false}) nameSub: ElementRef;
  // @ts-ignore
  @ViewChild('descriptionSubCertification', {static: false}) descriptionSub: ElementRef;
  // @ts-ignore
  @ViewChild('typeValiditeSubCertification', {static: false}) typeValiditeSub: ElementRef;
  // @ts-ignore
  @ViewChild('dateSubCertification', {static: false}) dateSub: ElementRef;
  // @ts-ignore
  @ViewChild('delaySubCertification', {static: false}) delaySub: ElementRef;
  // @ts-ignore
  @ViewChild('idSubCertification', {static: false}) idSub: ElementRef;
  // tslint:disable-next-line:max-line-length
  addingSuccessSous: boolean;
  defaultSelectValidite: any;
  private myForm: any;
  public siteFormArray: any;
  public array = [];
  private array_sites_id: any;
  private val: any;
  private site_id_certification: any;
  public sites= [];
  // tslint:disable-next-line:max-line-length
  constructor(private configService: ConfigService, private formBuilder: FormBuilder,private router: Router, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, private siteService: SiteService, private userService: UserService, private certificationService: CertificationService, private profileService: ProfileService, private subCertificationService: SubCertificationService) { }
  // tslint:disable-next-line:variable-name
  public single_sub_certificats = {
    name: null,
    description: null,
    type_validite: null,
    date: null,
    type: null,
    delay: null,
    id: null
  };
  public certification = {
    name: null,
    description: null,
    type_validite: null,
    date: null,
    type: null,
    delay: null,
    id: null
  };
  // tslint:disable-next-line:variable-name
  public single_certificats = {
    name: null,
    description: null,
    type_validite: null,
    date: null,
    type: null,
    delay: null,
    id: null
  };
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  public addingSuccess: boolean;
  public profiles: any;
  // tslint:disable-next-line:variable-name
  public list_sous_certificats: any;
  readioSelected: number;
  readioSelectedSous: number;



  // tslint:disable-next-line:variable-name
  certification_id: any;

  ngOnInit() {
    this.configService.checkUSerAccessByProfile(this.profile_id);
   /* console.log("routes");
    console.log(this.activatedRoute.snapshot.url); // array of states*/
    // console.log(this.activatedRoute.snapshot.url[4].path);
    this.get_certificat(this.activatedRoute.snapshot.url[4].path);
    this.listingSousCertificat(this.activatedRoute.snapshot.url[4].path);
    this.get_list_sites();
    this.myForm = this.formBuilder.group({
      site_id: this.formBuilder.array([])
    });
    this.siteFormArray = <FormArray>this.myForm.controls.site_id;
  }
  // tslint:disable-next-line:variable-name
  get_certificat(id_certificat: any) {
    this.spinner.show();
    this.certificationService.getOneCertificat(id_certificat)
      .subscribe(res => {
        // @ts-ignore
        this.single_certificats = res.data;
        this.site_id_certification = res.site_id;
        this.site_id_certification.forEach(element => this.check(element,true));
        if (this.single_certificats.type_validite=="2" ) {
          this.readioSelected = 2;
        }
        else {
          this.readioSelected = 1;
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }
  edit_certificat() {

    const body = {
      name: this.single_certificats.name,
      description: this.single_certificats.description,
      type_validite: this.readioSelected,
      date: this.single_certificats.date,
      delay: this.single_certificats.delay,
      type: this.single_certificats.type,
      id: this.single_certificats.id,
      array_site_id : this.siteFormArray.value,
    };
    console.log(body);
    this.certificationService.updateCertificats(body)
      .subscribe(res => {
        this.addingSuccess = true;
        setTimeout (() => {
          this.addingSuccess = false;
        }, 5000);
      }, (err) => {
        console.log(err);
      });
  }

  changeValidite(e) {
    console.log(e.target.value);
    this.readioSelected = e.target.value;
    this.single_certificats.type_validite=this.readioSelected;
  }
  listingSousCertificat(id: any) {
    this.certificationService.getSousCertificatbyCertificat(id)
      .subscribe(res => {
        // @ts-ignore
        this.list_sous_certificats = res.data;
        console.log(this.list_sous_certificats);
      }, (err) => {
        console.log(err);
      });
  }

  add_certificat() {
    // tslint:disable-next-line:max-line-length
    if (this.certification.name === null || this.certification.description === null ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez vos saisies !'
      });
      return false;
      // tslint:disable-next-line:align
    }
    const body = {
      name: this.certification.name,
      description: this.certification.description,
      type_validite: this.certification.type_validite,
      date: this.certification.date,
      certificat_id: this.activatedRoute.snapshot.url[4].path,
      type: this.certification.type,
      delay: this.certification.delay
    };
    console.log(body);
   // return false;
    this.certificationService.addCertificats(body)
      .subscribe(res => {
        this.addingSuccessSous = true;
        // @ts-ignore: Unreachable code error
        this.messageText = res.message;
        this.certification.name = '';
        this.certification.description = '';
        this.certification.type_validite = '';
        this.certification.date = '';
        this.certification.type = '';
        this.listingSousCertificat(this.activatedRoute.snapshot.url[4].path);
      }, (err) => {
        console.log(err);
      });
  }

  changeValiditeSous(e) {
    console.log(e.target.value);
    this.readioSelectedSous = e.target.value;
  }

  edit_sous_certificat(id) {
    this.router.navigate(['/dashboard/administrator/sous-certifications/editer/' + id], { relativeTo: this.activatedRoute});

/*    const body = {
      name: this.nameSub.nativeElement.value,
      description: this.descriptionSub.nativeElement.value,
      type_validite: this.typeValiditeSub.nativeElement.value,
      date: this.dateSub.nativeElement.value,
      delay: this.delaySub.nativeElement.value,
      id: this.idSub.nativeElement.value
    };
    console.log(body);
    this.subCertificationService.updateSousCertificat(body)
      .subscribe(res => {
        this.addingSuccess = true;
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });*/
  }
  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.sites = res.data;
        this.array_sites_id = res.id;
        console.log(res.data);
      }, (err) => {
        console.log(err);
      });
  }

  check(site_id: number, event: boolean){


    if(event) {
      this.siteFormArray.push(new FormControl(site_id));
    } else {
      let index = this.siteFormArray.controls.findIndex(x => x.value == site_id)
      this.siteFormArray.removeAt(index);
    }

  }

  check_value(site_id){
    this.val = this.siteFormArray.value.includes(site_id);
    if(this.val){
      return true;
    }
    else{
      return false;
    }
  }

}
