import { Component, OnInit } from '@angular/core';
import {FormCreatorService} from '../../../../services/form-creator.service';
import {Router} from '@angular/router';
import {DataService} from '../../../../services/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.css']
})
export class CreateFormComponent implements OnInit {
  constructor(private formCreatorService: FormCreatorService, private router: Router, private dataService: DataService) {
    this.myData = '';
    this.dataService.getData().subscribe(data => {
      // do stuff with the data, for example:
      this.myData = data.htmlCode;
      // console.log(this.myData);
    });
    this.dataService.getDataGroup().subscribe(data => {
      // do stuff with the data, for example:
      this.myDataGroup = data.htmlCodeGroup;
      console.log(this.myDataGroup);
    });

  }
// tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));
  // tslint:disable-next-line:variable-name
  public connected_id = this.user_conntected.id;
  // tslint:disable-next-line:variable-name
  public form_creator = {
    name: null,
    firstname: null,
    phone: null,
    password: null,
    confirm_password: null,
    site_id: null,
    email: null,
    login: null,
    profile_id: null,
    custom_profile_id: null,
    publish: 0
  };
  public myData: any;
  public myDataGroup: any;
  groups: any;
  // tslint:disable-next-line:variable-name
  groups_id: any;
  // tslint:disable-next-line:variable-name
  showGif: boolean;
  // tslint:disable-next-line:variable-name
  label_form_auto_inscription: any;

  ngOnInit() {
    console.log(this.myData);
    console.log(this.myDataGroup);
    this.get_site_group(this.site_id);
  }

  create_form() {
    if (this.site_id === 23) {
      const body = {
        content_form: 'NLMK form',
        site_id: this.site_id,
        user_id: this.connected_id
      };
      console.log(body);
      this.formCreatorService.createForm(body)
        .subscribe(res => {
          this.router.navigate(['dashboard/administrator/formulaire']);
        }, (err) => {
          console.log(err);
        });
    } else {
      // tslint:disable-next-line:max-line-length
      if (this.groups_id === 'null'
        || typeof this.groups_id === 'undefined'
        || this.label_form_auto_inscription === 'null'
        || typeof this.label_form_auto_inscription === 'undefined') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Les champs avec (*) sont obligatoires '
        });
        return false;
      } else {
       /* console.log(this.myData);
        return false;*/
        const body = {
          content_form: this.myData,
          site_id: this.site_id,
          user_id: this.connected_id,
          group_id: this.groups_id,
          label: this.label_form_auto_inscription,
        };
        console.log(body);
        this.formCreatorService.createForm(body)
          .subscribe(res => {
            this.router.navigate(['dashboard/administrator/formulaire']);
          }, (err) => {
            console.log(err);
          });
      }

    }
  }
  // tslint:disable-next-line:variable-name
  get_site_group(site_id) {
    this.showGif = true;
    this.formCreatorService.getListOfGroupBySite(site_id)
      .subscribe(res => {
        console.log(res);
        this.groups = res.data;
        this.showGif = false;
      }, (err) => {
        console.log(err);
      });
  }
  getGroupChoose(event: any) {
    this.groups_id = event.target.value;
  }
}
