import { Component, OnInit } from '@angular/core';
import {SiteService} from '../../../services/site.service';
import {UserService} from '../../../services/user.service';
import {ProfileService} from '../../../services/profile.service';
import Swal from 'sweetalert2';
import {ConfigService} from '../../../services/config.service';
import {FormCreatorService} from '../../../services/form-creator.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public single_client = {
    name: null,
    firstname: null,
    phone: null,
    password: null,
    confirm_password: null,
    site_id: null,
    email: null,
    login: null,
    profile_id: null,
    custom_profile_id: null,
    publish: 0
  };
  public sites: any;
  public toggle1: any;
  public toggle2: any;
  public sites_admin: any;
  passwordIsValid = false;
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  public addingSuccess: boolean;
  public profiles: any;
  public user_id: any;
  public showCustomProfile: boolean;
  not_success_Message: boolean;
  success_Message: boolean;
  text: any;
  text_success: any;
  text_not_success: any;
  status: boolean;
  // tslint:disable-next-line:max-line-length
  loadingAdd: boolean;
  // tslint:disable-next-line:max-line-length
  public showGif: boolean;
  public groups: any;
  // tslint:disable-next-line:variable-name
  public admin_group_list: any;
  // tslint:disable-next-line:variable-name
  public groups_id: any;
  public name: any;
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));

  // tslint:disable-next-line:max-line-length
  constructor(private formCreatorService: FormCreatorService, private siteService: SiteService, private userService: UserService, private profileService: ProfileService, private configService: ConfigService) { }

  ngOnInit() {
    this.user_id = this.user_conntected.id;
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.get_list_sites();
    this.get_list_sites_by_user_id();
    this.get_list_profile();
    if (this.profile_id === 11) {
      this.get_list_group_admin();
    } else {
      this.get_site_group(this.site_id);
    }
  }
  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.sites_admin = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  get_list_sites_by_user_id() {
    this.siteService.get_sites_by_user_id(this.user_id)
      .subscribe(res => {
        this.sites = res.site_search;
      }, (err) => {
        console.log(err);
      });
  }
  get_list_profile() {
    this.profileService.getProfile()
      .subscribe(res => {
        this.profiles = res.data;
      }, (err) => {
        console.log(err);
      });
  }

  showNotMessageSuccess(text){

    this.text = text;
    this.not_success_Message = true;

    setTimeout (() => {
      this.not_success_Message = false;
    }, 5000);

  }

  showMessageSuccess(text){

    this.text = text;
    this.success_Message = true;

    setTimeout (() => {
      this.success_Message = false;
    }, 5000);

  }


  add_client() {
    // tslint:disable-next-line:max-line-length
    if (this.single_client.profile_id === null
      || this.single_client.profile_id === ''
      || this.single_client.name === null
      || this.single_client.name === ''
      || this.single_client.firstname === null
      || this.single_client.firstname === ''
      || this.single_client.password === null
      || this.single_client.password === ''
      || this.single_client.site_id == null
      || this.single_client.site_id === ''
      || this.single_client.email == null
      || this.single_client.email === ''
      || this.single_client.login == null
      || this.single_client.login === ''
    ) {
      this.showNotMessageSuccess('Vérifiez vos saisies !');

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez vos saisies !'
      });
      return false;
    } else if (this.single_client.password !== this.single_client.confirm_password) {
      this.showNotMessageSuccess('Mot de passe non identique !');

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Mot de passe non identique !'
      });
      return false;
    } else { // @ts-ignore
      // @ts-ignore
      if (this.single_client.publish === '1' && this.single_client.profile_id === '13' && this.single_client.custom_profile_id === null) {
        this.showNotMessageSuccess('Veuillez sélectionnez le profil adapté  sur ELMS !');


        Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Veuillez sélectionnez le profil adapté  sur ELMS !'
            });
            return false;
          } else {
            const body = {
              name: this.single_client.name,
              firstname: this.single_client.firstname,
              password: this.single_client.password,
              login: this.single_client.login,
              publish: this.single_client.publish,
              email: this.single_client.email,
              site_id: this.single_client.site_id,
              profile_id: this.single_client.profile_id,
              custom_profile_id: this.single_client.custom_profile_id,
              group_id: this.groups_id,
              user_connected_login: this.user_conntected.login,
            };
            this.userService.addUser(body)
              .subscribe(res => {
                this.addingSuccess = true;
                // @ts-ignore
                this.status = res.success;
                // @ts-ignore
                this.single_client.name = '';
                this.single_client.firstname = '';
                this.single_client.password = '';
                this.single_client.site_id = '';
                // @ts-ignore
                this.single_client.publish = '';
                this.single_client.email = '';
                if(this.status == true){
                  this.showMessageSuccess('Ajout effectué, ajouter votre groupe directement sur talensoft');
                }
                else{
                  this.showNotMessageSuccess('Erreur');
                }
              }, (err) => {
                console.log(err);
              });
          }
    }
  }
  passwordValid(event) {
    this.passwordIsValid = event;
  }

  showPublishing(value) {
    if (value === '1') {
    this.showCustomProfile = true;
    } else {
      this.showCustomProfile = false;
    }
  }
  // tslint:disable-next-line:variable-name
  get_site_group(site_id) {
    this.showGif = true;
    this.formCreatorService.getListOfGroupBySite(site_id)
      .subscribe(res => {
        this.groups = res.data;
        this.showGif = false;
      }, (err) => {
        console.log(err);
      });
  }
  get_list_group_admin() {
    this.showGif = true;
    this.formCreatorService.getListOfGroupforAdmin()
      .subscribe(res => {
        this.groups = res.data;
        this.showGif = false;
      }, (err) => {
        console.log(err);
      });
  }
  getGroupChoose(event: any) {
    this.groups_id = event.target.value;
  }

  changeType(input_field_password, num) {
    if (input_field_password.type === 'password') {
      input_field_password.type = 'text';
    } else {
      input_field_password.type = 'password';
    }

    if (num === 1) {
      this.toggle1 = !this.toggle1;
    } else {
      this.toggle2 = !this.toggle2;
    }
  }

}
