import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {ImportService} from '../../../../services/import.service';
import {UserService} from '../../../../services/user.service';
import {Subscription} from "rxjs";
import {User} from "../../../../models/user.model";
import {Site} from "../../../../models/site.model";
import {Profile} from "../../../../models/profile.model";
import {Groupe} from "../../../../models/groupe.model";

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {

  constructor(private http: HttpClient,private importService: ImportService,private userService: UserService) { }

  ngOnInit() {
  }


  requiredFileType:string;
  public array2: any;
  public property = 0;
  public UserImport = [];
  public array: any[];
  file: File = null;
  fileName = '';
  uploadProgress:number;
  uploadSub: Subscription;
  fileToUpload: File | null = null;

  uploadDocument(file) {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      console.log(fileReader.result);
    }
    fileReader.readAsText(this.file);

  }
  handleFileInput(e) {
    this.file = e.target.files[0];
    this.uploadDocument(this.file);
    console.log(this.file);
  }



  onFileSelected(event) {
    this.file = event.target.files[0];

    let Tab = [];
    const riskyChars = ['=', '+', '-','*'];

    const reader = new FileReader();
    reader.onload = (evt) => {
      const xmlData: string = (evt as any).target.result;
      console.log(xmlData);

      //others

      let csv: any = reader.result;
      let allTextLines = [];
      allTextLines = csv.split(/\r|\n|\r/);

      // //Table Headings
      // let headers = allTextLines[0].split(';');
      // let data = headers;
      // let tarr = [];
      // for (let j = 0; j < headers.length; j++) {
      //   tarr.push(data[j]);
      // }
      // //Pusd headings to array variable
      // this.lines.push(tarr);


      // Table Rows

      let arrl = allTextLines.length;

      for(let i = 1; i < arrl; i++){
        const tab_user = allTextLines[i].split(';');

        if(allTextLines[i].length != 0){

          let UserTab = [];
      // Details recover informations from csv

          // let UserDetails = new User();
          // let UserSite = new Site();
          // let UserGroupe = new Groupe();
          // let UserProfile = new Profile();
          // let publicated = '';

          // UserDetails.name = tab_user[0];
          // UserDetails.firstname = tab_user[1];
          // UserDetails.email = tab_user[2];
          // UserDetails.password = tab_user[3];
          // UserDetails.login = tab_user[4];
          // UserSite.name = tab_user[5];
          // UserDetails.start_date = tab_user[6];
          // UserDetails.end_date = tab_user[7];
          // UserGroupe.name = tab_user[8];
          // UserProfile.name = tab_user[9];
          // UserDetails.active = tab_user[10];
          // publicated = tab_user[11];


          const lenght = riskyChars.length;
            tab_user.forEach( function(item){
              let property = lenght;
              riskyChars.forEach( function(value){
                const IsIn = item.includes(value);

                if(IsIn == true){
                  console.log('error');
                }
                else{
                  if(property == 1){
                    UserTab.push(item);
                  }
                  property = property - 1;
                }
              });
            });

            console.log(UserTab);
          this.UserImport.push(UserTab);

        }
      }
    };
    reader.readAsText(this.file);

    this.fileName = this.file.name;
    const formData = new FormData();
    formData.append("thumbnail", this.file);
  }

  // tslint:disable-next-line:variable-name
  onUpload() {

    if (this.file) {
      const body = {
        reportProgress: true,
        UserArray: this.UserImport,
    };
      this.importService.import_csv(body)
        .subscribe(res => {
          console.log(res);
        }, (err) => {
          console.log(err);
        });
    }
    else{
      console.log("Rien");
    }

  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }
}
