import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../services/login.service';
import {UserService} from '../../../services/user.service';
import {ConfigService} from '../../../services/config.service';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name
  public profile_id = localStorage.getItem('profile_id');
  // tslint:disable-next-line:variable-name
  public photo_url ;
  public takeInHand = localStorage.getItem('takeInHand');
  // tslint:disable-next-line:variable-name
  public admin_login = localStorage.getItem('admin_login');
  // tslint:disable-next-line:variable-name
  public profile_label ;
  // tslint:disable-next-line:variable-name
  public single_user = {
    name : null,
    photo : null,
  };
  constructor(public translate: TranslateService,
              public pageTitle: Title,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private spinner: NgxSpinnerService,
              private userService: UserService,
              private config: ConfigService) {
    translate.addLangs(['fr', 'en']);
    translate.setDefaultLang('fr');
    pageTitle.setTitle('Espace personnel | Plateforme CertiKap');

    this.photo_url = this.config.urlDEV_images;
    if (this.user_conntected !== null) {
      this.get_single_user_connected_details(this.user_conntected.user_id ? this.user_conntected.user_id : this.user_conntected.id);
    }
  }

  ngOnInit() {
  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }

  logout_user() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tokenCurrentConnected');
    localStorage.removeItem('profile_id');
    localStorage.removeItem('site_id');
    localStorage.removeItem('takeInHand');
    localStorage.removeItem('connect_like_verificateur');
    this.loginService.logout().subscribe(
      res => {
        if(res.status == 'success'){
          localStorage.removeItem('currentUser');
          localStorage.removeItem('tokenCurrentConnected');
          localStorage.removeItem('profile_id');
          localStorage.removeItem('site_id');
          localStorage.removeItem('takeInHand');
          localStorage.removeItem('connect_like_verificateur');
          this.router.navigate(['/']);

          setTimeout(() => {
            location.reload();
          }, 1000);
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur s\'est produite lors de la déconnexion',
            footer: '<a href>Equipe Kaptitude</a>'
          });
        }
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Une erreur s\'est produite lors de la déconnexion',
          footer: '<a href>Equipe Kaptitude</a>'
        });
      }
    );
  }
  // tslint:disable-next-line:variable-name
  get_single_user_connected_details(id_user: number) {
    this.userService.getUser(id_user)
      .subscribe(res => {
        this.single_user.name = res.data.name;
        this.single_user.photo = this.photo_url + '' + res.data.photo;
      }, (err) => {
        console.log(err);
      });
  }

  sso_certikap() {
    this.spinner.show();
    const body = {
      // ssoKey : 'h-kaptitude'
      Id : this.user_conntected.id,
      ssoActive : this.user_conntected.active,
      ssoKey : this.user_conntected.login
    };
    this.userService.sso_connexion_direct_to_talensoft(body)
      .subscribe(res => {
        // @ts-ignore
        if (res.sso_data === '') {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Votre compte est inconnu sur Talensoft, veuillez contacter l\'administrateur pour plus détails.',
            footer: '<a href>Equipe Kaptitude</a>'
          });
          return false;
        }
        this.spinner.hide();
        // @ts-ignore
        window.location.replace(this.configService.ELMS_url + 'sso.php?skey=' + res.sso_data, "_blank");
      }, (err) => {
        console.log(err);
      });
  }


  // tslint:disable-next-line:variable-name
  returner(login_t) {
    this.spinner.show();
    const body = {
      login: login_t
    };
    // @ts-ignore
    this.userService.take_in_hand(body)
      .subscribe(res => {
        this.loginService.logout_take_in_hand();
        // @ts-ignore
        if (res.status === 'success') {
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          localStorage.setItem('takeInHandOf', 'no');
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res.user);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          // @ts-ignore
          if (res.profile_id === 10 || res.profile_id === 8 || res.profile_id === 13) {
            window.location.reload();
            this.router.navigate(['dashboard/inspector']);
            return false;
          }
          window.location.reload();
          this.router.navigate(['dashboard/administrator']);
          return false;

        }

        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }
}
