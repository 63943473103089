import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../services/login.service';
import {UserService} from '../../../services/user.service';
import {ConfigService} from '../../../services/config.service';
import { ExportToCsv } from 'export-to-csv';
import {NgxSpinnerService} from 'ngx-spinner';
import {ExportationService} from '../../../services/exportation.service';
import {CertificationService} from "../../../services/certification.service";
import {VerificationService} from '../../../services/verification.service';
import {SubCertificationService} from "../../../services/sub-certification.service";
import {FormArray, FormBuilder, FormControl} from "@angular/forms";
import {Certificat} from "../../../models/certificat.model";
import {Verification} from "../../../models/verification.model";
import {SubCertificat} from "../../../models/sub-certificat.model";

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));

  // tslint:disable-next-line:variable-name
  public photo_url;
  // tslint:disable-next-line:variable-name
  public single_user = {
    name: null,
    photo: null,
    email: null,
    password: null,
    firstname: null,
    id: null,
  };
  previewUrl: any = null;
  successMessage: boolean;
  text: any;
  exportate = {
    type: null,
    completude: null,
  };
  public choose;
  public certificate_choose;
  public sub_certificate_choose;
  public verification_choose;
  public dataToExport = [];
  public label: any;
  public certificate_label: any;
  public sub_certificate_label: any;
  public verification_label: any;
  public urlDownload: any;

  // @ts-ignore
  public sessions: any;
  public u_remote_id: any;
  // tslint:disable-next-line:variable-name
  public site_id: any;
  // tslint:disable-next-line:variable-name
  public link: any;
  // tslint:disable-next-line:variable-name
  public link_status: any;
  // tslint:disable-next-line:variable-name
  public this_link: any;
  public session_id: any;
  public messeNotUser: boolean;
  public showDownloadButton: boolean;
  // tslint:disable-next-line:variable-name
  public date_export: any;
  public certificats: any;
  public verifications: any;
  public lenght_certificats: any;
  public value_certificat: any;
  public CertificationFormArray: any;
  public SubCertificationFormArray: any;
  public VerificationFormArray: any;
  public myFormCertifications: any;
  public myFormVerifications: any;
  public myFormSubCertifications: any;
  public masterSelected: any;
  private user_id: any;

  constructor(public translate: TranslateService,
              public pageTitle: Title,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private userService: UserService,
              private formBuilder: FormBuilder,
              private spinner: NgxSpinnerService,
              private exportationService: ExportationService,
              private certificationService: CertificationService,
              private verificationService: VerificationService,
              private subCertificationService: SubCertificationService,
              private config: ConfigService) {
    this.urlDownload = config.urlDownload;
    this.photo_url = this.config.urlDEV_images;
    if (this.user_conntected !== null) {
      if(this.user_conntected.id)
      {
        this.get_single_user_connected_details(this.user_conntected.id);
      }
      else{
        this.get_single_user_connected_details(this.user_conntected.user_id);
      }
    }
  }

  ngOnInit() {

    this.get_list_sessions_infos();
    this.config.checkUSerAccessByProfile(this.profile_id);
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
    }
    this.u_remote_id = this.user_conntected.u_remote_id;
    this.profile_id = this.user_conntected.profile_id;

    if(this.user_conntected.id)
    {
      this.user_id = this.user_conntected.id;
    }
    else{
      this.user_id = this.user_conntected.user_id;
    }

    //list
    this.get_list_certification_by_site_user(this.user_id);
    this.get_list_verification_by_site_user(this.user_id);
    this.get_list_sub_certification_by_site_user(this.user_id);

    //Form CheckBox
    this.myFormCertifications = this.formBuilder.group({
      certification_id: this.formBuilder.array([])
    });
    this.CertificationFormArray = <FormArray>this.myFormCertifications.controls.certification_id;
    this.myFormSubCertifications = this.formBuilder.group({
      sub_certification_id: this.formBuilder.array([])
    });
    this.SubCertificationFormArray = <FormArray>this.myFormSubCertifications.controls.sub_certification_id;
    this.myFormVerifications = this.formBuilder.group({
      verification_id: this.formBuilder.array([])
    });
    this.VerificationFormArray = <FormArray>this.myFormVerifications.controls.verification_id;

  }

  AllCertificats: Certificat[] = [];
  AllVerifications: Verification[] = [];
  AllSubCertificats: SubCertificat[] = [];


  // tslint:disable-next-line:variable-name
  get_single_user_connected_details(id_user: number) {
    this.userService.getUser(id_user)
      .subscribe(res => {
        this.single_user.firstname = res.data.firstname;
        this.single_user.name = res.data.name;
        this.single_user.email = res.data.email;
        this.single_user.password = res.data.password;
        this.single_user.id = res.data.id;
        this.single_user.photo = this.photo_url + '' + res.data.photo;
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  get_list_sessions_infos() {
    this.userService.get_sessions_list()
      .subscribe(res => {
        this.sessions = res.data;
      }, (err) => {
        console.log(err);
      });
  }

  chooseType(e) {
    this.choose = e.target.value;
    this.label = e.target.options[e.target.options.selectedIndex].text;
  }

  chooseCertificats(e) {
    this.certificate_choose = e.target.value;
    this.certificate_label = e.target.options[e.target.options.selectedIndex].text;


  }

  chooseVerifications(e) {
    this.verification_choose = e.target.value;
    this.verification_label = e.target.options[e.target.options.selectedIndex].text;
  }

  chooseSubCertificats(e) {
    this.sub_certificate_choose = e.target.value;
    this.sub_certificate_label = e.target.options[e.target.options.selectedIndex].text;
  }


  formatDate() {
    // tslint:disable-next-line:one-variable-per-declaration
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    const hour = d.getHours();
    const minute = d.getMinutes();
    const seconde = d.getSeconds();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    const dateJoin = [year, month, day].join('');
    const hourJOin = [hour, minute, seconde].join('');
    return [dateJoin, hourJOin].join('_');
  }

  choose_config(choose) {
    if (choose === '0') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des ' + this.label,
        filename: this.label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['ID', 'Intitulé']
      };
      return options;
    }
    if (choose === '1') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des ' + this.label,
        filename: this.label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['ID', 'Titre du parcours', 'Description', 'Mot clés', 'Date d import ', 'Durée estimée ', 'Date de création', 'Référence', 'Pas commencé', 'En cours', 'Non validé', 'Validé', 'Total', 'Score', 'Temps moyen', 'Site', 'ID Site', 'Session', 'ID session']
      };
      return options;
    }
    if (choose === '2') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des ' + this.label,
        filename: this.label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['ID', 'Intitulé', 'Date de début', 'Date de fin', 'Etat ', 'Pas commencé', 'En cours', 'Non validé', 'Validé', 'Total', 'Score', 'Temps moyen', 'ID Site', 'Nom du site']
      };
      return options;
    }
    if (choose === '3') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des ' + this.label,
        filename: this.label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['ID', 'Titre de l\'UA ', 'Description', 'Durée estimée', 'Sites ', 'Parcours', 'Id Parcours']
      };
      return options;
    }
    if (choose === '4') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des ' + this.label,
        filename: this.label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['Nom de l\'apprenant', 'Est connecté(e) ?']
      };
      return options;
    }
    // certificat choose
    if (choose === '5') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation du certificat ' + this.certificate_label,
        filename: this.certificate_label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        // headers: ['Entreprise', 'GroupeModel', 'nom', 'prénom', 'email ', 'identifiant', 'Code CertiKap', 'nom Certificat', 'Titre du parcours','ID du parcours', 'date de certification', 'date de fin de validité', 'statut du certificat']
        headers: ['']
      };
      return options;
    }
    //certificats all
    if (choose === '6') {
      if (this.certificate_choose) {
        const options = {
          fieldSeparator: ';',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Exportation des certificats',
          filename: this.label + '_' + this.formatDate(),
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false,
          // tslint:disable-next-line:max-line-length
          headers: ['']
        };
        return options;

      }
    }

    //verificat choose
    if (choose === '7') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation de la vérification ' + this.verification_label,
        filename: this.verification_label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['']
      };
      return options;
    }

    //sub_certification choose
    if (choose === '8') {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation de la sub certification ' + this.sub_certificate_label,
        filename: this.sub_certificate_label + '_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['']
      };
      return options;
    }
  }


  exportation() {
    this.spinner.show();
    this.link_status = false;
    this.link = 'En cours de téléchargement';
    const body = {
      export_type: this.choose,
      completude: this.exportate.completude,
      session_id: this.session_id,
      user_id: this.u_remote_id,
      profile_id: this.user_conntected.profile_id,
      id: this.user_id,
      array_certification: this.CertificationFormArray.value,
      array_verification: this.VerificationFormArray.value,
      array_sub_certification: this.SubCertificationFormArray.value,
    };
    this.userService.exportation(body)
      .subscribe(res => {
        // Sites Export
        if (res.export_type === '0') {
          const options = this.choose_config(this.choose);
          res.data.forEach((rdata, index) => {
            const data = {
              ID: rdata.site_id,
              Intitule: rdata.site_name,
            };
            this.dataToExport.push(data);
          });
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(this.dataToExport);
        }

        // Parcours Export
        if (res.export_type == '1') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export + '.csv';
            window.location.href = this.urlDownload + '' + this.date_export;
          }
        }

        // Session Export
        if (res.export_type == '2') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export + '.csv';
            window.location.href = this.urlDownload + '' + this.date_export;
          }

        }
        // Ua Export
        if (res.export_type === '3') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export + '.csv';
            window.location.href = this.urlDownload + '' + this.date_export;
          }

        }
        // Export des personnes connectés
        if (res.export_type == '4') {
          this.dataToExport = [];
          const options = this.choose_config(this.choose);
          if (res.data.length === 0) {
            this.messeNotUser = true;
            this.spinner.hide();
            return false;
          }
          res.data.forEach((rdata, index) => {
            const data = {
              nom_apprenant: rdata.user.fullname,
              est_connecte: rdata.user.is_connected.length === 0 ? 'Non' : 'Oui',
            };

            this.dataToExport.push(data);
          });
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(this.dataToExport);
        }

        if (res.export_type == '5') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export;
            window.location.href = this.urlDownload + '' + this.date_export;
            this.link = window.location.href = this.urlDownload + '' + this.date_export;
          }
        }

        if (res.export_type == '6') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export;
            window.location.href = this.urlDownload + this.date_export;
          }

        }

        if (res.export_type == '7') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export;
            window.location.href = this.urlDownload + '' + this.date_export;
          }

        }
        if (res.export_type == '8') {
          if (res.success === true) {
            this.showDownloadButton = true;
            this.date_export = res.data.date_export;
            window.location.href = this.urlDownload + '' + this.date_export;
          }

        }
        this.spinner.hide();

      }, (err) => {
        console.log(err);
      });

  }

  chooseCompletude(e) {
    this.exportate.completude = e.target.value;
  }

  get_list_certification_export() {
    this.certificationService.getCertificat()
      .subscribe(res => {
        // @ts-ignore
        this.certificats = res.data.certificat;
      }, (err) => {
        console.log(err);
      });
  }

  get_list_certification_by_site_user(user_id) {
    this.certificationService.getCertificatBySiteUser(user_id)
      .subscribe(res => {
        // @ts-ignore
        this.AllCertificats = res.data;
      }, (err) => {
        console.log(err);
      });
  }

  get_list_verification_by_site_user(user_id) {
    this.verificationService.getVerificationBySiteUser(user_id)
      .subscribe(res => {
        // @ts-ignore
        this.AllVerifications = res.data;
      }, (err) => {
        console.log(err);
      });
  }

  get_list_sub_certification_by_site_user(user_id) {
    this.subCertificationService.getSubCertificatBySiteUser(user_id)
      .subscribe(res => {
        // @ts-ignore
        this.AllSubCertificats = res.data;
      }, (err) => {
        console.log(err);
      });
  }


  get_list_verification_export() {
    this.verificationService.getVerification()
      .subscribe(res => {
        // @ts-ignore
        this.verifications = res.data.verifications;
        // this.lenght_verification = res.data.verifications.length;
        // this.value_verificat = 0;
      }, (err) => {
        console.log(err);
      });
  }

  choose_certificate(certificate_choose) {
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Exportation des ' + this.label,
      filename: this.label + '_' + this.formatDate(),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      // tslint:disable-next-line:max-line-length
      headers: ['ID', 'Intitulé']
    };
    return options;
  }

  //Check Certification with all

  check_certificats(certification_id: number, event: boolean) {
    if (event) {
      this.CertificationFormArray.push(new FormControl(certification_id));
    } else {
      let index = this.CertificationFormArray.controls.findIndex(x => x.value == certification_id)
      this.CertificationFormArray.removeAt(index);

    }
  }

              // The master checkbox will check/ uncheck all items
  checkUncheckAllCertificat(event: boolean) {
    if (event) {
      this.AllCertificats.forEach(element =>{
        let index = this.CertificationFormArray.controls.findIndex(x => x.value == element.id)
        if(index < 0){
          this.check_certificats(element.id,true)

        }
      });
    }else{
      this.AllCertificats.forEach(element => this.check_certificats(element.id,false));
      this.AllCertificats.forEach(element =>{
        let index = this.CertificationFormArray.controls.findIndex(x => x.value == element.id)
        if(index <= 0){
          this.check_certificats(element.id,false)

        }
      });
    }

  }

  check_value_certificat(certificat_id){
    let val = this.CertificationFormArray.value.includes(certificat_id);
    if(val){
      return true;
    }
    else{
      return false;
    }
  }

//Check Verification
  check_verifications(verification_id: number, event: boolean) {
    if (event) {
      this.VerificationFormArray.push(new FormControl(verification_id));
    } else {
      let index = this.VerificationFormArray.controls.findIndex(x => x.value == verification_id)
      this.VerificationFormArray.removeAt(index);
    }
  }

  //Check SubCertification with all

  check_sub_certificats(sub_certification_id: number, event: boolean) {
    if (event) {
      this.SubCertificationFormArray.push(new FormControl(sub_certification_id));

    } else {
      let index = this.SubCertificationFormArray.controls.findIndex(x => x.value == sub_certification_id)
      this.SubCertificationFormArray.removeAt(index);

    }
  }

  checkUncheckAllSubCertificat(event: boolean) {
    if (event) {
      this.AllSubCertificats.forEach(element =>{
        let index = this.SubCertificationFormArray.controls.findIndex(x => x.value == element.id)
        if(index < 0){
          this.check_sub_certificats(element.id,true)

        }
      });
    }else{
      this.AllSubCertificats.forEach(element => this.check_sub_certificats(element.id,false));
      this.AllSubCertificats.forEach(element =>{
        let index = this.SubCertificationFormArray.controls.findIndex(x => x.value == element.id)
        if(index <= 0){
          this.check_sub_certificats(element.id,false)

        }
      });
    }

  }

  check_value_sub_certificat(sub_certificat_id){
    let value_sub = this.SubCertificationFormArray.value.includes(sub_certificat_id);
    if(value_sub){
      return true;
    }
    else{
      return false;
    }
  }





}
