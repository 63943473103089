import { Injectable, OnInit } from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {User} from '../models/user.model';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {ConfigService} from './config.service';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';

const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token
  }),
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  constructor(private http: HttpClient, private config: ConfigService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  // tslint:disable-next-line:contextual-lifecycle use-lifecycle-interface
  ngOnInit() {
    this.config.redirectTools();
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  login(email: string, password: string) {
    return this.http.post<any>(this.config.urlDEV + '/login', { email, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user.status === 'success') {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(user.token));
          this.currentUserSubject.next(user);
          return user;
        } else {
          return;
        }
        // this.router.navigate(['/login'], { queryParams: { returnUrl: "state.url" }});
        return false;

      }));
  }
  loginWithToken(id: string, token: string) {
    return this.http.post<any>(this.config.urlDEV + '/login_with_token', { id, token })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user.status === 'success') {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(user.token));
          this.currentUserSubject.next(user);
          return user;
        } else {
          return;
        }
        // this.router.navigate(['/login'], { queryParams: { returnUrl: "state.url" }});
        return false;
      }));
  }

  logout_take_in_hand() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tokenCurrentConnected');
    localStorage.removeItem('profile_id');
    localStorage.removeItem('site_id');
    localStorage.removeItem('takeInHand');
    localStorage.removeItem('connect_like_verificateur');
    this.currentUserSubject.next(null);
  }

  // tslint:disable-next-line:variable-name
  logout(): Observable<any> {
    const data = {};
    return this.http.post(this.config.urlDEV + '/logout',data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  sso_connexion_lms_to_certikap(data) {
    return this.http.post(this.config.urlDEV + '/lms/sso_connexion',data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  connexion_with_login(data) {
    return this.http.post(this.config.urlDEV + '/connexion/login',data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
}
