import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {ConfigService} from '../../../services/config.service';
import {OrderPipe} from 'ngx-order-pipe';
import {ExportToCsv} from 'export-to-csv';
import {Router} from '@angular/router';
import {LoginService} from '../../../services/login.service';
import {SiteService} from '../../../services/site.service';
@Component({
  selector: 'app-verificateur',
  templateUrl: './verificateur.component.html',
  styleUrls: ['./verificateur.component.css']
})
export class VerificateurComponent implements OnInit {
  page = 1;
  order = 'user.name';
  reverse = false;
// tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));
  // tslint:disable-next-line:variable-name
  // tslint:disable-next-line:variable-name radix
  public site_connected_id = parseInt(localStorage.getItem('site_id'));

  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:max-line-length variable-name
  public admin_login = this.user_conntected.login;
  // tslint:disable-next-line:variable-name
  public connected_id = this.user_conntected.id;
  // tslint:disable-next-line:variable-name
  public fake_userid = 9999;
  public users: any;
  public Allusers: any;
  public sites: any;
  public userToExport: any[];
  // tslint:disable-next-line:variable-name
  public user_sites: any;
  // tslint:disable-next-line:variable-name
  public count_verificateur: any;
  show = 10;
  vQuery: string;
  addingSuccess: boolean;
  sortedCollection: any[];
  showLoadConnection: boolean;
  // tslint:disable-next-line:variable-name
  showLoader = true;

  // tslint:disable-next-line:variable-name
  showLabel = true;
  // tslint:disable-next-line:max-line-length
  public showLoaderExport: boolean;
  // tslint:disable-next-line:max-line-length
  displayBody = true;
  isTousChecked: boolean = true;
  isActifsChecked: boolean = false;
  isNonActifsChecked: boolean = false;
  // tslint:disable-next-line:max-line-length
  constructor(private siteService: SiteService, private loginService: LoginService, private router: Router, private orderPipe: OrderPipe, private userService: UserService, private spinner: NgxSpinnerService, private configService: ConfigService) {
    this.sortedCollection = orderPipe.transform(this.users, 'user.name');
  }


  ngOnInit() {
    this.get_list_sites();
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.get_list_verificateurs(this.profile_id, this.site_id, this.connected_id);
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  // tslint:disable-next-line:variable-name
  get_list_verificateurs(profile_id, site_id, user_id) {
    this.spinner.show();
    this.userService.getListVerificateur(profile_id, site_id, user_id)
      .subscribe(res => {
        // @ts-ignore
        this.count_verificateur = res.data.length;
        // @ts-ignore
        this.users = res.data;
        this.Allusers = [ ...this.users ];
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
  }
  increaseShow() {
    this.spinner.show();
    this.show += 10;
    this.spinner.hide();
  }

  // tslint:disable-next-line:variable-name
  delete_client(id_client: any) {
    Swal.fire({
      title: 'Etes-vous sûr(e) ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        // @ts-ignore
        this.userService.deleteClient(id_client)
          .subscribe(res => {
            this.addingSuccess = true;
            this.get_list_verificateurs(this.profile_id, this.site_id, this.connected_id);
          }, (err) => {
            console.log(err);
          });
        Swal.fire(
          'Supprimé !',
          'Vérificateur supprimé avec succès !',
          'success'
        );
      }
    });
  }
  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.sites = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  // tslint:disable-next-line:variable-name
  get_client_sites(id_site, profile) {
    this.spinner.show();
    this.siteService.get_site_verificateur(id_site, profile)
      .subscribe(res => {
        // @ts-ignore
        this.count_verificateur = res.data.length;
        // @ts-ignore
        this.users = res.data;
        this.Allusers = [ ...this.users ];
        this.afficherTousLesUtilisateurs();
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }
  filterSite(value) {
    this.get_client_sites(value, this.profile_id);
  }
  exporter() {
    const dataToExport = [];
    if(this.profile_id !== 11) {
      const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Exportation des vérificateurs',
        filename: 'liste_verificateur_' + this.formatDate(),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        // tslint:disable-next-line:max-line-length
        headers: ['Noms', 'Prénoms', 'Date de début', 'Date de fin', 'Statut']
      };
      this.users.forEach((rdata, index) => {
        const data = {
          nom: rdata.user.name,
          prenom: rdata.user.firstname,
          date_debut: rdata.user.start_date === '01/01/1970' ? '' : rdata.user.start_date,
          date_fin: rdata.user.end_date === '01/01/1970' ? '' : rdata.user.end_date,
          status: rdata.user.active === 1 ? 'Actif' : 'Non actif',
        };
        dataToExport.push(data);
      });
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(dataToExport);
    } else {
      this.showLoaderExport = true;
      this.spinner.show();
      this.userService.getVerificateurs_export(this.profile_id, this.site_id, this.connected_id)
        .subscribe(res => {
          // @ts-ignore
          this.userToExport = res.data;
          this.spinner.hide();
          const options = {
            fieldSeparator: ';',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Exportation des vérificateurs',
            filename: 'liste_verificateur_' + this.formatDate(),
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            // tslint:disable-next-line:max-line-length
            headers: ['Noms', 'Prénoms', 'Sites', 'Date de début', 'Date de fin', 'Statut']
          };
          this.userToExport.forEach((rdata, index) => {
            // tslint:disable-next-line:variable-name
            let nameSite = '';
            // tslint:disable-next-line:variable-name
            rdata.sites.forEach((rsite, index_site) => {
              nameSite += rsite.name + ' - ';
            });
            const data = {
              nom: rdata.user.name,
              prenom: rdata.user.firstname,
              site: nameSite.slice(0, -2),
              date_debut: rdata.user.start_date === '01/01/1970' ? '' : rdata.user.start_date,
              date_fin: rdata.user.end_date === '01/01/1970' ? '' : rdata.user.end_date,
              status: rdata.user.active === 1 ? 'Actif' : 'Non actif',
            };
            dataToExport.push(data);
          });
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(dataToExport);
        });

    }


  }
  formatDate() {
    // tslint:disable-next-line:one-variable-per-declaration
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    const hour = d.getHours();
    const minute = d.getMinutes();
    const seconde = d.getSeconds();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    const dateJoin = [year, month, day].join('');
    const hourJOin = [hour, minute, seconde].join('');
    return [dateJoin, hourJOin].join('_');
  }

  // tslint:disable-next-line:variable-name
  prise_en_main(login_t, email_t) {
    this.showLoadConnection = true;
    this.spinner.show();
    const body = {
      login: login_t,
      email: email_t
    };
    // @ts-ignore
    this.userService.take_in_hand(body)
      .subscribe(res => {
        this.loginService.logout_take_in_hand();
        // @ts-ignore
        if (res.status === 'success') {
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          localStorage.setItem('takeInHand', 'yes');
          localStorage.setItem('admin_login', this.admin_login);
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res.user);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          // @ts-ignore

          if (res.profile_id === 8) {
            window.location.reload();
            this.router.navigate(['dashboard/inspector']);
            return false;
          }
          window.location.reload();
          this.router.navigate(['dashboard/administrator']);
          return false;

        }

        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }
  // tslint:disable-next-line:variable-name
  listingSiteUser(user_id, profile_id) {
    this.displayBody = false;
    this.showLoader = true;
    this.showLabel = false;
    this.userService.getUserSiteAnd_Profil_InFront(user_id, profile_id)
      .subscribe(res => {
        this.displayBody = true;
        this.showLabel = true;
        // @ts-ignore
        this.user_sites = res.sites;
        this.showLoader = false;

      }, (err) => {
        console.log(err);
      });
  }
  handlePageChange(event) {
    this.page = event;
  }

  afficherTousLesUtilisateurs() {
    // Réinitialiser la liste des utilisateurs selon votre logique
    this.users = [...this.Allusers];
    this.count_verificateur = this.users.length;

    // Définir isTousChecked sur true et désactiver les autres boutons
    this.isTousChecked = true;
    this.isActifsChecked = false;
    this.isNonActifsChecked = false;
  }

  // Fonction pour filtrer les utilisateurs non actifs
  filtrerUtilisateurs(actif : number) {
    this.users = this.Allusers.filter((data) => data.user.active === actif);
    this.count_verificateur = this.users.length;

    // Définir isTousChecked sur false
    this.isTousChecked = false;
  }

}
