import { Injectable, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /* public urlDEV = 'http://institut-pasteur-api.local/api/v1';
   // tslint:disable-next-line:variable-name
   public urlDEV_images = 'http://institut-pasteur-api.local/uploads/users/';
   public urlDownload = 'http://institut-pasteur-api.local/uploads/generated-csv/gaip/exports/';
   public urlDownloadCertification = 'http://institut-pasteur-api.local/uploads/generated-csv/certifications/';
   public formURL = 'http://localhost:4200/v1/form/auto-inscription/';
   // tslint:disable-next-line:variable-name
   public ELMS_url = 'https://h-pasteur.elmg.net/';*/

  //PROD
  public urlDEV = 'https://api.certikap.com/api/v1';
  // tslint:disable-next-line:variable-name
  public urlDEV_images = 'https://api.certikap.com/uploads/users/';
  public urlDownload = 'https://api.certikap.com/sources/public/uploads/generated-csv/cron/exports/';
  public urlDownloadCertification = 'https://api.certikap.com/uploads/generated-csv/certifications/';
  public formURL = 'https://certikap.com/v1/form/auto-inscription/';
  // tslint:disable-next-line:variable-name
  public ELMS_url = 'https://kaptitude.elmg.net/';

  // DEV
  // public urlDEV = 'https://api-dev.certikap.com/api/v1';
  // // tslint:disable-next-line:variable-name
  // public urlDEV_images = 'https://api-dev.certikap.com/uploads/users/';
  // public urlDownload = 'https://api-dev.certikap.com/sources/public/uploads/generated-csv/cron/exports/';
  // public urlDownloadCertification = 'https://api-dev.certikap.com/uploads/generated-csv/certifications/';
  // public formURL = 'https://dev.certikap.com/v1/form/auto-inscription/';
  // // tslint:disable-next-line:variable-name
  // public ELMS_url = 'https://h-kaptitude.elmg.net/';


  // staging
  // public urlDEV = 'https://api-staging.certikap.com/api/v1';
  // // tslint:disable-next-line:variable-name
  // public urlDEV_images = 'https://api-staging.certikap.com/uploads/users/';
  // public urlDownload = 'https://api-staging.certikap.com/sources/public/uploads/generated-csv/cron/exports/';
  // public urlDownloadCertification = 'https://api-staging.certikap.com/uploads/generated-csv/certifications/';
  // public formURL = 'https://staging.certikap.com/v1/form/auto-inscription/';
  // // tslint:disable-next-line:variable-name
  // public ELMS_url = 'https://kaptitude.elmg.net/';


  public  path;
  public  lastURLSegment;
  public connectLikeVerifcateur = localStorage.getItem('connect_like_verificateur');
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService) {
    const pageURL = window.location.href;
    this.lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
  }

  redirectTools() {
    // return false;
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
      this.spinner.hide();
    }
    // tslint:disable-next-line:variable-name
    const profile_id = localStorage.getItem('profile_id');
    if (profile_id !== null ) {
      // @ts-ignore
      if (profile_id === '13' || this.router.url === '/') {
        this.router.navigate(['dashboard/inspector']);
      } else if (profile_id === '8' || this.router.url === '/') {
        this.router.navigate(['dashboard/inspector']);
      } else if (typeof this.connectLikeVerifcateur !== 'undefined' && this.connectLikeVerifcateur === 'yes') {
        this.router.navigate(['dashboard/inspector']);
      } else {
        this.router.navigate(['dashboard/administrator']);
        return false;
      }
    }
  }

  checkUSerAccessByProfile(profile) {
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
    }
    this.path = this.router.url;
    if (profile === 13 || profile === 8) {
      this.router.navigate(['dashboard/inspector']);
    }
    if (profile === 8) {
      this.router.navigate(['dashboard/inspector']);
    }
    if (profile === 12 && this.path === '/dashboard/administrator/verificateurs') {
      this.router.navigate(['dashboard/administrator/verificateurs']);
    }
    if (profile === 12 && this.path === '/dashboard/administrator/intervenants') {
      this.router.navigate(['dashboard/administrator/intervenants']);
    }

    if (profile === 12 && this.path === '/dashboard/administrator/intervenants') {
      this.router.navigate(['dashboard/administrator/intervenants']);
    }
    if (profile === 1) {
      if (this.path === '/dashboard/administrator/clients'
        || this.path === '/dashboard/administrator/verifications'
        || this.path === '/dashboard/administrator/certifications'
        || this.path === '/dashboard/administrator/sous-certifications'
        // || this.path === '/dashboard/administrator/exports'
        || this.path === '/dashboard/administrator/apprenant/documents'
        || this.path === '/dashboard/administrator/apprenant/certificats'
        || this.path === '/dashboard/administrator/formulaire/create'
      ) {
        this.router.navigate(['dashboard/administrator']);
      }
    }
    // profile_id==2 || profile_id==4 || profile_id==5 || profile_id==6 || profile_id==9 || profile_id==10
    if (profile === 4 || profile === 2 || profile === 5 || profile === 6 || profile === 9 || profile === 10 ) {
      if (this.path === '/dashboard/administrator/clients'
        || this.path === '/dashboard/administrator/verifications'
        || this.path === '/dashboard/administrator/certifications'
        || this.path === '/dashboard/administrator/sous-certifications'
        // || this.path === '/dashboard/administrator/exports'
        || this.path === '/dashboard/administrator/superviseurs'
        || this.path === '/dashboard/administrator/apprenant/documents'
        || this.path === '/dashboard/administrator/apprenant/certificats'
        || this.path === '/dashboard/administrator/formulaire/create'
      ) {
        this.router.navigate(['dashboard/administrator']);
      }
    }

    if (profile === 3 ) {
      if (this.path === '/dashboard/administrator/clients'
        || this.path === '/dashboard/administrator/verifications'
        || this.path === '/dashboard/administrator/certifications'
        || this.path === '/dashboard/administrator/sous-certifications'
        || this.path === '/dashboard/administrator/exports'
        || this.path === '/dashboard/administrator/superviseurs'
        || this.path === '/dashboard/administrator/verificateurs'
        || this.path === '/dashboard/administrator/intervenants'
        || this.path === '/dashboard/administrator/apprenant/documents'
        || this.path === '/dashboard/administrator/apprenant/certificats'
        || this.path === '/dashboard/administrator/formulaire/create'
      ) {
        this.router.navigate(['dashboard/administrator']);
      }
    }
    if (this.connectLikeVerifcateur === 'yes') {
      if (this.path === '/dashboard/administrator/clients'
        || this.path === '/dashboard/administrator/verifications'
        || this.path === '/dashboard/administrator/certifications'
        || this.path === '/dashboard/administrator/sous-certifications'
        || this.path === '/dashboard/administrator/exports'
        || this.path === '/dashboard/administrator/superviseurs'
        || this.path === '/dashboard/administrator/verificateurs'
        || this.path === '/dashboard/administrator/intervenants'
        || this.path === '/dashboard/administrator/apprenant/documents'
        || this.path === '/dashboard/administrator/apprenant/certificats'
        || this.path === '/dashboard/administrator/formulaire/create'
      ) {
        this.router.navigate(['dashboard/inspector']);
      }
      if (typeof this.lastURLSegment !== 'undefined' && Number(this.lastURLSegment)) {
        this.router.navigate(['dashboard/inspector']);
      }
    }

  }

}
