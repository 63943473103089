import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));
  // tslint:disable-next-line:variable-name
  public connected_id = this.user_conntected.id;
  constructor() { }

  ngOnInit() {
  }

}
